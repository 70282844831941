/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';
@import '../../../../../../src/style/abstract/theme-abstract';

.BikeFormSuccess {
    // TODO style the element
    &-Title {
        color: var(--cms-theme-color-1-text);
        text-align: center;
    }

    &-Description {
        color: var(--body-heading-color);
    }

    &-Actions {
        &-Title {
            color: var(--cms-theme-color-1-text);
        }
    }

    &-Button {
        color: var(--body-heading-color);
        background-color: var(--cms-button-secondary-background-color);
        border-color: var(--cms-button-secondary-border-color);
        font-size: 12;
        width: auto;
        margin: auto 10px auto auto;
        text-decoration: none;
        display: inline-block;
        text-transform: none;
        transition-property: background-color, color, border;
        will-change: background-color, color, border;
        transition-timing-function: ease-out;
        transition-duration: .25s;
        cursor: pointer;
        border-radius: var(--cms-button-border-radius);
        font-weight: var(--cms-button-font-weight);
        // font-size: var(--cms-button-font-size);
        line-height: var(--cms-button-line-height);
        padding: var(--cms-button-padding);
        border-width: var(--cms-button-border-width);
        border-style: var(--cms-button-border-style);
    }
}
