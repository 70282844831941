// Modifiers
.has-shadow {
    box-shadow: var(--cms-box-shadow);
}

.grey-background {
    background-color: var(--cms-grey-background);
}

.white-background {
    background-color: var(--cms-white-background);
}

.theme-color-1-background {
    background-color: var(--cms-theme-color-1-background);
}

.transparent-background {
    background-color: transparent;
}

.center-content {
    text-align: center;
}

.use-light-text {
    color: var(--cms-light-font-color);

    a,
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: var(--cms-light-font-color);
    }
}

.use-red-text {
    color: var(--cms-theme-color-1-text);

    a,
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: var(--cms-theme-color-1-text);
    }
}

.full-height {
    height: var(--height-100vh);
    min-height: 700px;

    @include before-desktop {
        height: auto;
        min-height: 0;
    }

    .cms-row,
    .cms-row.has-vertical-col-spacing,
    .cms-row.has-vertical-col-spacing.double-col-spacing {
        margin-bottom: 0;
    }
}

.cms-spacer {
    display: block;
    width: 100%;
    height: var(--cms-vertical-padding-x1);
    margin: 0;

    > p {
        display: none;
    }

    &.double-spacer-height {
        height: var(--cms-vertical-padding-x2);
    }

    &.triple-spacer-height {
        height: var(--cms-vertical-padding-x3);
    }
}
