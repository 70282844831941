.Table {
    $block: &; // Setting this selectctor as a targetable parent variable for later use.

    border-collapse: separate;
    border-spacing: 0 1.2rem;
    margin-bottom: 0;

    @include mobile {
        margin-top: -1.2rem;
        margin-bottom: -1.2rem;
    }

    @include tablet {
        border-spacing: 0 var(--b2b-spacing-size);
        margin-top: calc(var(--b2b-spacing-size) * -1);
        margin-bottom: calc(var(--b2b-spacing-size) * -1);
    }

    @include desktop {
        border-collapse: collapse;
        border-spacing: 0;
    }
    
    // Start reset of base _table.scss styles
    table-layout: initial;
    
    & th, & td {
        min-width: initial;
    }
    
    & tr:nth-child(even), & thead tr {
        background-color: initial;
    }
    
    & thead tr {
        border-bottom: 0.1rem solid var(--secondary-base-color);
    }
    // End reset of base _table.scss styles

    & tfoot tr {
        border-top: 0.1rem solid var(--secondary-base-color);
    }
    
    width: 100%;

    &_style_cards {
        // Start reset of base _table.scss styles

        & thead tr {
            background-color: initial;
        }
        // End reset of base _table.scss styles

        border-collapse: separate;
        border-spacing: 0 var(--b2b-spacing-size);

        & thead #{$block}-Header {
            background-color: initial;
        }

        & thead {
            #{$block}-HeaderData {
                padding-bottom: 0;

                &:first-child {
                    padding-left: var(--b2b-spacing-size);
                }

                &:last-child {
                    padding-right: var(--b2b-spacing-size);
                }

                &_type_thumbnail {
                    padding-left: var(--b2b-spacing-size);
                    padding-right: var(--b2b-spacing-size);
                }
            }
        }

        & tbody {
            #{$block}-Item {
                box-shadow: var(--theme-box-shadow);
                //background-color: var(--white);
                border-radius: var(--theme-border-radius);
            }
        }

        & tbody {
            & #{$block}-ItemData {
                padding-right: var(--b2b-spacing-size);
                background-color: var(--white);
                
                @include desktop {
                    padding-top: var(--b2b-spacing-size);
                    padding-bottom: var(--b2b-spacing-size);
                }

                &:first-child {
                    padding-left: var(--b2b-spacing-size);
                    
                    @include desktop {
                        border-top-left-radius: var(--theme-border-radius);
                        border-bottom-left-radius: var(--theme-border-radius);
                    }

                    @include before-desktop {
                        padding-top: var(--b2b-spacing-size);
                    }
                }

                &:last-child {
                    @include desktop {
                        border-top-right-radius: var(--theme-border-radius);
                        border-bottom-right-radius: var(--theme-border-radius);
                    }

                    @include before-desktop {
                        padding-bottom: var(--b2b-spacing-size);
                    }
                }

                &_type_thumbnail {
                    padding-left: var(--b2b-spacing-size);
                    padding-right: var(--b2b-spacing-size);
                    
                    &:first-child {
                        @include before-desktop {
                            padding-top: 0;
                            padding-bottom: 0;
                        }
                    }
                }
            }
        }
    }

    &_style_onecard {
        // Start reset of base _table.scss styles
        & tr:nth-child(even) {
            @include desktop {
                background-color: var(--white);
            }
        }

        & thead {
            tr {
                background-color: initial;
            }
        }
        // End reset of base _table.scss styles

        border-collapse: separate;
        border-spacing: 0;

        @include before-desktop {
            margin-top: 0;
            margin-bottom: 0;
        }

        & thead { 
            #{$block}-Header {
                background-color: initial;
                display: table-row;
            }
        }

        & thead {
            #{$block}-HeaderData {
                padding-top: 3.6rem;
                display: table-cell;

                @include before-desktop {
                    white-space: normal;
                }

                &:first-child {
                    padding-left: var(--b2b-spacing-size);
                }

                &:last-child {
                    padding-right: var(--b2b-spacing-size);
                }

                &_type_thumbnail {
                    padding-left: var(--b2b-spacing-size);
                    padding-right: var(--b2b-spacing-size);
                }

                &_hidden {
                    &_onDesktop {
                        @include desktop {
                            display: none;
                        }
                    }
        
                    &_beforeDesktop {
                        @include before-desktop {
                            display: none;
                        }
                    }
                }
            }
        }

        & tbody {
            box-shadow: var(--theme-box-shadow);
            //background-color: var(--white);
            border-radius: var(--theme-border-radius);

            & #{$block}-Item {
                box-shadow: none;

                &:first-child #{$block}-ItemData {
                    @include desktop {
                        padding-top: var(--b2b-spacing-size);
                    }

                    &:first-child {
                        border-top-left-radius: var(--theme-border-radius);

                        @include before-desktop {
                            border-top-right-radius: var(--theme-border-radius);
                            padding-top: var(--b2b-spacing-size);
                        }
                    }
    
                    &:last-child {
                        @include desktop {
                            border-top-right-radius: var(--theme-border-radius);
                        }
                    }
                }
    
                &:last-child #{$block}-ItemData {
                    @include desktop {
                        padding-bottom: var(--b2b-spacing-size);
                    }
                    
                    &:first-child {
                        @include desktop {
                            border-bottom-left-radius: var(--theme-border-radius);
                        }
                    }
    
                    &:last-child {
                        border-bottom-right-radius: var(--theme-border-radius);

                        @include before-desktop {
                            border-bottom-left-radius: var(--theme-border-radius);
                            padding-bottom: var(--b2b-spacing-size);
                            border-bottom: 0;
                        }
                    }
                }
            }

            & th {
                @include before-desktop {
                    text-align: left;
                }
            }

            & #{$block}-ItemData {
                background-color: var(--white);
                
                @include desktop {
                    border-top: 0;
                }
                
                @include before-desktop {
                    border-bottom: 0.1rem solid var(--secondary-base-color);
                }

                &:first-child {
                    padding-left: var(--b2b-spacing-size);
                    border-radius: 0;
                    @include before-desktop {
                        padding-top: 0.6rem;
                    }
                }
    
                &:last-child {
                    padding-right: var(--b2b-spacing-size);
                    border-radius: 0;
                    @include before-desktop {
                        padding-bottom: 0.6rem;
                    }
                }
    
                &_type_thumbnail {
                    padding-left: var(--b2b-spacing-size);
                    padding-right: var(--b2b-spacing-size);
                }
            }
        }

        &ForceFull {
            & tbody {
                & .Table-Item {
                    &:first-child {
                        & .Table-ItemData {
                            &:first-child {
                                @include before-desktop {
                                    border-top-right-radius: 0;
                                }
                            }

                            &:last-child {
                                @include before-desktop {
                                    border-top-right-radius: var(--theme-border-radius);
                                    padding-top: var(--b2b-spacing-size);
                                }
                            }
                        }
                    }

                    &:last-child {
                        & .Table-ItemData {
                            &:first-child {
                                @include before-desktop {
                                    border-bottom-left-radius: var(--theme-border-radius);
                                    padding-bottom: var(--b2b-spacing-size);
                                }
                            }

                            &:last-child {
                                @include before-desktop {
                                    border-bottom-left-radius: 0;
                                    border-bottom-right-radius: var(--theme-border-radius);
                                }
                            }
                        }
                    }
                }
            }

            & .Table-Item {
                &Data {
                    @include before-desktop {
                        clear: none;
                        float: none;
                        width: min-content;
                    }
                }
            }
        }
    }

    &-Header {
        display: none;

        @include desktop {
            display: table-row;
        }
    }

   &-HeaderData {
       min-width: initial;
       padding: 0 var(--b2b-spacing-size) var(--b2b-spacing-size) 0;
       text-align: center;
       font-weight: bold;
       white-space: nowrap;
       font-size: 1.4rem;
       display: none;

        @include desktop {
            display: table-cell;
        }

        &:last-child {
            padding-right: 0;
        }

        &_align { 
            &_left {
                text-align: left;
            }

            &_right {
                text-align: right;
            }
        }

        &_size {
            &_shrink {
                width: 1px;
                white-space: nowrap;
            }
        }

        &_fontWeight { 
            &_normal {
                font-weight: normal;
            }

            &_bold {
                font-weight: bold;
            }
        }
    }

    &-Item {
        box-shadow: var(--theme-box-shadow);
        border-radius: var(--theme-border-radius);
        
        @include desktop {
            box-shadow: none;
            background-color: transparent;
            border-radius: 0;
        }
        
        &:first-child #{$block}-ItemData {
            @include desktop {
                padding-top: var(--b2b-spacing-size);
            }
        }

        & #{$block}-ItemData {
            &:first-child {
                @include before-desktop {
                    padding-top: var(--b2b-spacing-size);
                }
            }

            &:last-child {
                @include before-desktop {
                    padding-bottom: var(--b2b-spacing-size);
                }
            }
        }

        &_type_child {
            @include desktop {
                transform: translateX(2%) scale(0.96);
            }

            &::before {
                @include before-desktop {
                    content: '';
                    width: 100%;
                    height: 30px;
                    bottom: 100%;
                    position: absolute;
                    display: block;
                    background-color: var(--white);
                }
            }

            .Table_style_cards tbody & #{$block}-ItemData {
                @include desktop {
                    background-color: #f5f5f5;
                }

                &:first-child {
                    @include before-desktop {
                        border-top-left-radius: 0;
                        border-top-right-radius: 0;
                        border-top: 1px solid var(--secondary-base-color);
                    }
                }
            }
        }
    }

    &-ItemData {
        position: relative;
        padding: 0.6rem var(--b2b-spacing-size);
        vertical-align: middle;
        text-align: right;
        font-weight: normal;
        font-size: 1.4rem;
        color: var(--title-color);
        height: auto;
        clear: left;
        float: left;
        width: 100%;
        background-color: var(--white);
        border-bottom: 0.1rem solid var(--secondary-base-color);
        transition: padding-top linear 100ms;

        @include desktop {
            padding: 0 var(--b2b-spacing-size) var(--b2b-spacing-size) 0;
            text-align: center;
            clear: initial;
            float: initial;
            width: auto;
            background-color: transparent;
            border-bottom: none;
        }

        &:first-child {
            border-top-left-radius: var(--theme-border-radius);
            border-top-right-radius: var(--theme-border-radius);

            @include desktop {
                border-radius: 0;
            }
        }

        &:last-child {
            border-bottom-left-radius: var(--theme-border-radius);
            border-bottom-right-radius: var(--theme-border-radius);
            border-bottom: none;

            @include desktop {
                padding-right: 0;
                border-radius: 0;
            }
        }

        &_type {
            &_thumbnail {
                @include desktop {
                    width: 14rem;
                }
                
                @include tablet {
                    height: 16rem;
                }
    
                @include mobile {
                    height: 12rem;
                }

                & div.Image {
                    @include before-desktop {
                        display: block;
                        height: 100% !important;
                    }
                    
                    & img.Image-Image {
                        @include before-desktop {
                            object-fit: contain;
                        }
                    }
                }
            }
    
            &_address {
                @include desktop {
                    text-align: left;
                }
    
                & address {
                    font-style: normal;
    
                    & span {
                        display: block;
                    }
                }
            }

            &_hasMobileButton {
                & .Table-Label {
                    @include mobile {
                        display: none;
                    }
                } 
            }
        }

        &_align { 
            &_left {
                @include desktop {
                    text-align: left;
                }

                &Mobile {
                    @include mobile {
                        text-align: left;
                    }
                }

                &Tablet {
                    @include tablet {
                        text-align: left;
                    }
                }
            }

            &_right {
                @include desktop {
                    text-align: right;
                }

                &Mobile {
                    @include mobile {
                        text-align: right;
                    }
                }

                &Tablet {
                    @include tablet {
                        text-align: right;
                    }
                }
            }
        }

        &_hidden {
            &_onDesktop {
                @include desktop {
                    display: none;
                }
            }

            &_beforeDesktop {
                @include before-desktop {
                    display: none;
                }
            }
        }

        &_size {
            &_shrink {
                width: 1px;
                white-space: nowrap;
            }
        }

        &_fontWeight { 
            &_normal {
                font-weight: normal;
            }

            &_bold {
                font-weight: bold;
            }
        }

        &_color_primary {
            color: var(--primary-base-color);
        }

        &_color_primaryLight {
            color: var(--primary-light-color);
        }
    
        &_color_success {
            color: var(--table-color-success);
        }

        &_color_info {
            color: var(--table-color-info);
        }
    
        &_color_danger {
            color: var(--table-color-danger);
        }
    }

    &-Label {
        display: block;
        float: left;
        height: 100%;
        padding-right: 1.2rem;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        margin: auto;
        color: var(--body-content-color);

        @include desktop {
            display: none;
        }
    }

    &-Footer {
        display: none;

        @include desktop {
            display: table-row;
        }
    }

    &-FooterData {
        min-width: initial;
        padding: var(--b2b-spacing-size) var(--b2b-spacing-size) 0 0;
        vertical-align: middle;
        text-align: right;
        font-weight: bold;
        font-size: 1.4rem;
        white-space: nowrap;
        height: auto;
        clear: left;
        float: left;
        width: 100%;
        background-color: var(--white);
        border-bottom: 0.1rem solid var(--secondary-base-color);

        @include desktop {
            text-align: center;
            clear: initial;
            float: initial;
            width: auto;
            background-color: transparent;
            border-bottom: none;
        }

        &:last-child {
            padding-right: 0;
        }

        &_align {
            &_left {
                @include desktop {
                    text-align: left;
                }
            }

            &_right {
                @include desktop {
                    text-align: right;
                }
            }
        }
    }

    &-Button {
        padding-left: 2.4rem;
        padding-right: 2.4rem;
        font-weight: normal;
        text-align: center;

        @include mobile {
            width: 100%;
            margin-top: var(--b2b-spacing-size-small);
        }
    }

    &-Link {
        text-decoration: underline;
        color: var(--primary-light-color);
        text-align: center;

        @include before-desktop {
            display: inline-block;
            text-decoration: none;
            padding: var(--button-padding);
            padding-left: 2.4rem;
            padding-right: 2.4rem;
            background-color: var(--primary-base-color);
            color: var(--white);
            border-radius: var(--theme-border-radius-button);
            font-size: 1.54rem;
        }

        @include mobile {
            width: 100%;
        }
    }

    &-ItemDataLink {
        text-decoration: underline;
    }

    &-Helper {
        &_isHiddenOnDesktop {
            @include desktop {
                display: none;
            }
        }

        &_isHiddenBeforeDesktop {
            @include before-desktop {
                display: none;
            }
        }

        &_isHiddenOnTablet {
            @include tablet {
                display: none;
            }
        }

        &_isHiddenAfterMobile {
            @include after-mobile {
                display: none;
            }
        }

        &_isHiddenOnMobile {
            @include mobile {
                display: none;
            }
        }
    }

    &-ChildArrow {
        font-size: 2.6rem;
        color: #ababab;
        line-height: 0;
        top: 3px;
        transform: rotateY(180deg) scale(1.04) translateX(55.4px);
    }
}
