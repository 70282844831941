/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/theme-abstract';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 .MyAccountDashboard {
    $block: &;
    display: grid;
    grid-template-columns: auto;
    grid-gap: 1.2rem;

    @include desktop {
        grid-template-columns: 1fr 1fr;
        grid-gap: 2.4rem;
    }

    &-Info {
        margin-bottom: 5px;
    }

    &-Welcome {
        font-weight: 500;
        margin-bottom: 0;

        @include desktop {
            grid-column: span 2;
        }
    }

    &-ActionButtons {
        display: flex;
        position: absolute;
        right: 0;
        top: -55px;

        @include before-desktop {
            display: none;
        }
    }

    &-ActionButton {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: fit-content;
        margin-left: 0;

        &:hover {
            text-decoration: none;

            & #{$block}-ActionButtonImage {
                border-color: var(--primary-base-color);
                background-color: var(--primary-base-color);

                &::after {
                    filter: invert(100%);
                }
            }
        }
    }

    &-ActionButtonImage {
        display: block;
        width: 3.6rem;
        height: 3.6rem;
        border: 2px solid var(--primary-light-color);
        border-radius: 4.8rem;
        margin: 0 3.6rem;

        &:last-child {
            margin-right: 0;
        }

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 3.6rem;
            height: 3.6rem;
        }

        &_type_documents {
            &::after {
                background: url('image/download.svg') no-repeat center;
                background-size: 14px;
                filter: invert(59%) sepia(52%) saturate(605%) hue-rotate(167deg) brightness(87%) contrast(90%);
            }
        }

        &_type_support {
            &::after {
                background: url('image/questionmark.svg') no-repeat center;
                background-size: 10px;
                filter: invert(59%) sepia(52%) saturate(605%) hue-rotate(167deg) brightness(87%) contrast(90%);
            }
        }

        &_type_profile {
            &::after {
                background: url('image/account.svg') no-repeat center;
                background-size: 14px;
                filter: invert(59%) sepia(52%) saturate(605%) hue-rotate(167deg) brightness(87%) contrast(90%);
            }
        }

        &_type_logout {
            &::after {
                background: url('image/logout.svg') no-repeat center;
                background-size: 16px;
                filter: invert(59%) sepia(52%) saturate(605%) hue-rotate(167deg) brightness(87%) contrast(90%);
            }
        }
    }

    &-ActionButtonText {
        color: var(--primary-light-color);
        padding-top: 0.4rem;
        
    }
    
    &-NewsTitle {
        font-size: 2.4rem;
        color: var(--primary-base-color);
        
        @include before-desktop {
            margin-top: 2.4rem;
        }
    }

    &-NewsContent {
        position: relative;
        display: flex;

        @include desktop {
            padding-bottom: 6rem !important;
            min-height: 40rem;
        }

        @include before-desktop {
            flex-direction: column;
        }

        &.Card {
            @include before-desktop {
                padding: 18px;
                //background-color: transparent;
                //box-shadow: none;
            }
        }
    }

    &-ProductBackordersTitle {
        font-size: 2.4rem;
        color: var(--primary-base-color);

        @include before-desktop {
            margin-top: 2.4rem;
        }
    }

    &-ProductBackordersContent {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        @include desktop {
            padding-bottom: 6rem !important;
            min-height: 40rem;
        }

        @include before-desktop {
            flex-direction: column;
        }

        &.Card {
            @include before-desktop {
                padding: 18px;
                //background-color: transparent;
                //box-shadow: none;
            }
        }
    }

    &-ShopButton {
        position: relative;
        text-align: center;
        margin: 2.4rem 0; 

        @include desktop {
            margin: 4.8rem 0;
            --button-padding: 2.4rem;
            grid-column: span 2;
        }

        &:hover {
            & #{$block}-ShopButtonExtra_isLeft {
                transform: translateY(-50%) scale(1.05) rotateZ(5deg);
            }

            & #{$block}-ShopButtonExtra_isRight{
                transform: translateY(-50%) scale(1.05) rotateZ(-5deg);
            }
        }
    }

    &-ShopButtonExtra {
        position: absolute;
        display: block;        
        pointer-events: none;
        top: 50%;
        transform: translateY(-50%);
        transition: .2s ease transform;

        &_isLeft {
            left: 0;
            width: 251px;
            height: 140px;
            background: url('http://lvsh.hoeks.dk/media/b2b-assets/dashboard/shop-button-extra-left.png') no-repeat center;
            background-size: contain;
        }

        &_isRight {
            right: 0;
            width: 261px;
            height: 140px;
            background: url('http://lvsh.hoeks.dk/media/b2b-assets/dashboard/shop-button-extra-right.png') no-repeat center;
            background-size: contain;
        }

        @include tablet {
            width: 168px;
            height: 90px;
        }

        @include mobile {
            width: 131px;
            height: 70px;
        }
    }

    &-RecentOrders {
        @include desktop {
            grid-column: span 2;
        }
    }

    &-RecentOrdersContent {
        position: relative;
    }

    &-RecentOrdersTitle {
        font-size: 2.4rem;
        color: var(--primary-light-color);
    }

    &-RecentOrdersTitleLink {
        display: inline-block;
        margin-left: var(--b2b-spacing-size);
        font-size: 1.2rem;
        color: var(--primary-base-color);
        text-decoration: underline;

        &:link, &:visited {
            color: var(--primary-base-color);
        }

        &:hover, &:active, &:focus {
            color: var(--primary-dark-color);
        }
    }

    &-ViewAll {
        display: flex;
        justify-content: center;
        @include desktop {
            position: absolute;
            bottom: var(--b2b-spacing-size);
            left: 50%;
            transform: translateX(-50%);
        }

        &_for_recentOrders {
            position: initial;
            transform: initial;
            display: flex;
            justify-content: center;
        }
    }

    &-ViewAllLink {
        font-size: var(--font-size);
        font-weight: 500;

        @include before-desktop {
            padding-top: var(--b2b-spacing-size);
        }
    }

    &-ViewAllIcon {
        display: inline-block;
        margin-left: 7px;
        //transition: transform linear 200ms; // flip animation for the arrow
        
        &::after {
            content: '';
            position: relative;
            display: inline-block;
            border-width: 0 0 1px 1px;
            border-style: solid;
            height: 6px;
            width: 6px;
            top: 50%;
            transform: translate(0, -50%) rotate(-45deg);
        }

        &_type {
            &_isExpanded {
                transform: rotateX(180deg);
            }
        }
    }
}