:root {
    --cms-image-banner-top-padding: 300px;

    @include before-desktop {
        --cms-image-banner-top-padding: 200px;
    }

    @include mobile {
        --cms-image-banner-top-padding: 150px;
    }
}

.cms-image-banner {
    &-section {
        overflow: visible;
    }

    > .cms-col-inner {
        padding-top: var(--cms-image-banner-top-padding);
        background-size: cover;
        background-position: center;
        border-radius: var(--theme-border-radius);
        overflow: hidden;
        z-index: 2;
        box-shadow: 0 0 0 0 transparent;
        transition: all ease .3s;
        height: 100%;

        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 70%;
            background: rgb(0,0,0);
            background: linear-gradient(0deg, rgba(0,0,0,.5) 0%, rgba(0,0,0,0) 100%);
            z-index: 2;
        }

        @at-root .cms-image-banner:hover & {
            box-shadow: 0 3px 4px 1px rgba(0,0,0,0.1);
        } 
    }

    &-content {
        z-index: 3;
        h2 {
            margin-bottom: 0;
        }

        p {
            font-weight: 500;

            &:last-of-type {
                margin-bottom: 1.2rem;
            }
        }
    }

    &-bg {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-position: center;
        background-size: cover;
        z-index: 1;
        transition: all ease .3s;
        transform: scale(1);

        @at-root .cms-image-banner:hover & {
            transform: scale(1.05);
        }
    }

    &-link {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 10;
        font-size: 0;
        opacity: 0; 
    }

    .cms-button {
        &-secondary {
            background-color: var(--white);
            border-color: var(--white);

            &:hover {
                background-color: var(--cms-button-secondary-hover-background-color);
                border-color: var(--cms-button-secondary-hover-background-color);
            }
        }
    }

    @include mobile {
        &.cms-col-12-sm:first-child {
            margin-bottom: var(--cms-vertical-padding-x1);
        }
    }
}