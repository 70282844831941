/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';
@import '../../../../../../src/style/abstract/theme-abstract';

.Popup-Content {
    .slick-slider {
        .slick-list {
            .slick-track {
                .slick-slide {
                    .SliderWidget-Figure {
                        .Image {
                            padding: 0;
                            height: auto;

                            .Image-Image {
                                height: auto !important;
                                position: relative;
                                object-fit: unset;
                                display: block;
                            }
                        }
                    }
                }
            }
        }

        .slick-arrow {
            background: var(--button-background);
            height: 40px;
            width: 40px;
            border-radius: 50%;
            border-top-left-radius: 50%;
            border-top-right-radius: 50%;
            border-bottom-right-radius: 50%;
            border-bottom-left-radius: 50%;
            z-index: 1;
            transition: all ease .3s;

            &::before {
                font-family: inherit;
                font-weight: bold;
                content: '';
                background-image: url(https://www.larryvsharry.com/pub/media/hoeks-pwa/arrow-left.svg);
                background-size: 18px;
                background-position: center;
                background-repeat: no-repeat;
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                display: block;
                opacity: 1;
            }

            &.slick-next {
                right: -22px;

                @include before-desktop {
                    right: -32px;
                }

                &::before {
                    transform: rotateZ(180deg);
                }
            }

            &.slick-prev {
                left: -22px;

                @include before-desktop {
                    left: -32px;
                }
            }

            &:hover {
                background: var(--button-hover-background);
            }
        }

        .slick-dots {
            bottom: 0;

            li {
                &::before {
                    display: none;
                }

                button {
                    &::before {
                        color: var(--primary-base-color);
                        font-size: 10px;
                    }
                }
            }
        }
    }
}
