/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.StoreFinder {
    &-Heading {
        font-size: 3rem;
        font-weight: bold;
    }

    &-MainContent {
        display: grid;
        grid-template-columns: 1fr;
        column-gap: 2.4rem;
        margin-bottom: 4.8rem;

        @include desktop {
            grid-template-columns: auto 25%;
        }
    }

    &-SelectList {
        grid-column-end: span 1;
        @include desktop {
            grid-column-end: span 2;
        }
    }

    &-Map {
        grid-template-columns: 1fr;
        padding: 0;
        height: 100vh;
        max-height: 70vh;

        .leaflet {
            &-container {
                height: 100vh;
                max-height: 70vh;
                font: inherit;

                a {
                    color: var(--link-color);
                }
            }

            &-marker {
                &-icon {
                    margin-top: -25px !important;
                    margin-left: -9px !important;
                }
            }

            &-popup {
                &-content {
                    margin: 0;
                    box-shadow: 0 6px 10px rgba(0, 0, 0, .4);
                    border-radius: var(--theme-border-radius);

                    &-wrapper {
                        background: transparent;
                        border-radius: 0;
                    }
                }
            }
        }
    }

    &-StoreCards {
        display: flex;
        padding: 0;
        flex-direction: column;
        grid-gap: 0!important;

        @include desktop {
            padding-right: 1.2rem;
            height: 100vh;
            max-height: 70vh;
            overflow-y: scroll;
        }
    }

    &-Store {
        padding: 2.4rem;
        background-color: var(--white);
        border: 1px solid var(--input-border-color);
        //background-image: url(https://www.larryvsharry.com/pub/media/hoeks-pwa/lvsh-grafik-svag.png);
        background-size: auto 100%;
        background-position: right bottom;
        background-repeat: no-repeat;
        transition: all ease .3s;
        min-height: 175px;

        @include desktop {
            border-radius: var(--theme-border-radius);
        }

        &:not(:last-child) {
            margin-bottom: 1.2rem;

            @include before-desktop {
                margin-bottom: -1px;
            }
        }

        .Button {
            margin: 0;
            font-weight: 600;
            font-size: 1.4rem;
        }

        &_isActive {
            border-color: var(--theme-dark-blue);
            //color: var(--white);
            //background-image: url(https://www.larryvsharry.com/pub/media/hoeks-pwa/lvsh-grafik.png);
        }
    }

    &-StoreInfo {
        &_type {
            &_name {
                margin-bottom: 1.2rem;
            }

            &_workingdays,
            &_hours {
                display: none;
            }

            &_url {
                margin-bottom: .6rem;
                margin-top: .6rem;
                font-size: 1.4rem;
                font-weight: 600;
            }

            &_number {
                display: none!important;
            }
        }
    }
}
