.cms-section {
    .bg-on-bd {
        display: none;
    }

    &.change-bg-on-bd {
        @include before-desktop {
            background-image: none !important;

            .bg-on-bd {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
            }
        }
    }

    .Image-Image {
        border-radius: var(--theme-border-radius);
    }

    .cms-col-inner {
        &.has-shadow {
            border-radius: var(--theme-border-radius);
            overflow: hidden;

            .Image-Image {
                border-radius: 0;
            }
        }
    }
}

.cms-button {
    &.popup-trigger {
        font-size: 0;
        padding: 0;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: inline-block;

        &::after {
            content: '+';
            font-size: 3.6rem;
            font-weight: 400;
            position: absolute;
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            top: 0;
        }
    }

    &-icon {
        height: 4.8rem;
        width: 4.8rem;
        padding: 0;
        background: transparent;
        border-radius: 50%;
        border-width: 2px;

        .Image {
            display: flex!important;
            align-items: center;
            justify-content: center;
            height: 100%!important;
    
            img {
                width: 2.6rem!important;
            }
        }

        &:hover {
            background-color: var(--white);
        }
    }
}

.cms-mailchimp-form {
    margin-top: 2.4rem;

    form {
        #mc_embed_signup_scroll {
            display: flex;
            flex-direction: row;
    
            input {
                border: none;
                height: 40px;
                padding: 0 20px;
                border: 1px solid rgba(0,0,0,0.1);

                &[type="email"] {
                    border-top-left-radius: var(--theme-border-radius-button);
                    border-bottom-left-radius: var(--theme-border-radius-button);
                }

                &[type="submit"] {
                    background: var(--primary-base-color);
                    color: var(--white);
                    transition: all ease .3s;
                    cursor: pointer;
                    border-top-right-radius: var(--theme-border-radius-button);
                    border-bottom-right-radius: var(--theme-border-radius-button);

                    &:hover {
                        background: var(--primary-dark-color); 
                    }
                }
            }
        }
    }
}

.cms-quote {
    display: block;
    padding: 2.4rem;
    font-size: 2rem;
    font-style: italic;
    background-color: #f9f9f9;
    color:#8d8d8d;
}

p {
    a {
        + a {
            margin-left: 0;
        }
    }
}

.rounded {
    border-radius: var(--theme-border-radius);
}

.use-light-text {
    a {
        &.cms-button:not(.cms-button-secondary) {
            color: var(--cms-button-primary-color);

            &:hover {
                color: var(--cms-button-primary-hover-color);
            }
        }
    }
}

.cms-video {
    video {
        width: 100%;
        height: auto;
        border-radius: var(--theme-border-radius);
        overflow: hidden;
    }
}