/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --input-type-number-width: 80px;
    --placeholder-gradient-color: #d9d9d9;
    --placeholder-image: linear-gradient(to right, #ebebeb 0, var(--placeholder-gradient-color) 15%, #ebebeb 30%);
    --placeholder-size: 1200px 100%;
    --placeholder-animation: placeholderanimation 2s linear infinite;
    --primary-divider-color: #f0f0f0;
    --secondary-error-color: #fff;
    --primary-error-color: #dc6d6d;
    --secondary-success-color: #fff;
    --primary-success-color: #7fcd91;
    --secondary-info-color: #79632e;
    --primary-info-color: #ffd166;
    --white: #{$white};
    --black: #{$black};
    --overlay-desktop-border-color: var(--grey-background-dark);

    // Theme text color
    --body-heading-color: #000;
    --body-heading-color-light: #eaeaea;
    --body-content-color: #030303;
    --body-content-color-light: #f0f0f0;

    // Theme specific colors
    --grey-background-main: #{$default-secondary-base-color};
    --grey-background-light: #f9f9f9;
    --grey-background-dark: #{$default-secondary-dark-color};
    --theme-dark-blue: #0f223d;
    --popup-background: rgba(0, 0, 0, .5);

    // Declare imported primary colors to change the theme's colors
    --primary-base-color: var(--imported_primary_base_color, #{$default-primary-base-color});
    --primary-dark-color: var(--imported_primary_dark_color, #{$default-primary-dark-color});
    --primary-light-color: var(--imported_primary_light_color, #{$default-primary-light-color});
    --secondary-base-color: var(--imported_secondary_base_color, #{$default-secondary-light-color});
    --secondary-dark-color: var(--imported_secondary_dark_color, #{$default-secondary-dark-color});
    --secondary-light-color: var(--imported_secondary_light_color, #{$default-secondary-light-color});

    // Table extra colors
    --table-color-success: #1a892f;
    --table-color-info: #cca650;
    --table-color-danger: #de2c32;

    // B2B extra colors
    --input-border-color: #e0e0e0;
    --b2b-title-color: #535353;
    --b2b-table-separator-color: #B7B7B7;
    --b2b-support-bg-color: #252C3A;

    // B2B extra sizes
    --b2b-spacing-size-small: 0.4rem;
    --b2b-spacing-size: 1.8rem;
    --b2b-spacing-size-big: 2.4rem;
    --b2b-spacing-size-bigger: 3.6rem;
    --b2b-border-radius: 0.8rem;
    --b2b-border-radius-big: 5.6rem;
    --b2b-font-size: 0.8rem;
    --b2b-input-label-margin-bottom: 0.4rem;
    --b2b-input-padding: 1rem; 
    --b2b-button-padding: 0.6rem 4.8rem;
    --b2b-button-padding-mobile: 1.4rem 1.4rem;

}
