/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/theme-abstract';

.Header {
    &-Wrapper {
        @at-root .isLoginPage & {
            display: none !important;
        }
    }

    @include desktop {
        --header-logo-height: 60px;
        --header-logo-width: 60px;
    }

    @include before-desktop {
        &_name {
            &_menu,
            &_category,
            &_filter {
                --header-color: var(--white);

                background: var(--primary-base-color);
                // top: unset;
                // bottom: calc(var(--navigation-tabs-height) + 2.4rem);
                color: var(--white);

                .Header {
                    &-Title {
                        color: var(--white);
                    }

                    // &-Button {
                    //     &_type {
                    //         &_back {
                    //             &::before {
                    //                 background-color: var(--white);
                    //             }

                    //             &::after {
                    //                 border-top-color: var(--white);
                    //                 border-left-color: var(--white);
                    //             }
                    //         }

                    //         &_close {
                    //             &::before,
                    //             &::after {
                    //                 background-color: var(--white);
                    //             }
                    //         }
                    //     }
                    // }
                }

                button {
                    color: var(--white);
                }
            }
        }
    }

    &-Nav {
        max-width: 100%;
        padding: 0;

        // this should not be nested, but it takes too long to moduify the component
        .StoreSwitcher {
            @include before-desktop {
                display: none;
            }
        }
    }

    &-Title {
        &_isVisible {
            @include before-desktop {
                margin-top: auto !important;
            }
        }
    }

    &-LogoWrapper {
        margin: 0;
        top: 50%;
        transform: translateY(-50%);
        left: 2.4rem;

        @include before-desktop {
            display: none;
        }

        .Image-Image {
            height: 60px;
            width: auto;
        }
    }

    &-ShopLink {
        height: var(--header-nav-height);
        width: auto;
        display: flex !important;
        align-items: center;
        justify-content: center;
        padding: 0 3.6rem;
        font-size: var(--button-font-size);
        font-weight: normal !important;
        margin-left: 1.2rem;
        border-radius: 0 !important;
        text-transform: none !important;

        @media screen and (min-width: 1025px) and (max-width: 1150px) {
            padding: 0 2.4rem !important;
            margin-right: 0;
        }

        @include before-desktop {
            display: none !important;
        }
    }

    &-BuilderLink,
    &-BuilderLink.Button {
        height: var(--header-nav-height);
        width: auto;
        align-items: center;
        justify-content: center;
        padding: 0 3.6rem !important;
        border-radius: 0;

        @include before-desktop {
            display: none !important;
        }

        @include desktop {
            display: flex !important;
        }

        @media screen and (min-width: 1025px) and (max-width: 1150px) {
            padding: 0 2.4rem !important;
            margin: 0;
        }

        &::after {
            content: '';
            position: absolute;
            top: 100%;
            height: 2px;
            width: calc(100% + 2px);
            background: var(--button-background);
            left: -1px;
            display: block;
        }
    }

    &-Button {
        &_type {
            &_share {
                @include before-desktop {
                    display: none;
                }
            }

            @include desktop {
                &_account {
                    padding: 0;
                    margin-right: 1.2rem;
                }

                &_minicart {
                    padding: 0;
                    margin-right: 1.2rem;
                }
            }

            &_account {
                @media screen and (max-width: 1150px) {
                    margin-right: 0;
                }
            }

            &_minicart {
                @media screen and (max-width: 1150px) {
                    margin-right: 0;
                }
            }
        }
    }

    &-MinicartButtonWrapper {
        margin: 0;
        padding: 0;
        height: 35px;
        width: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-MinicartIcon {
        top: 2px;

        &::before,
        &::after {
            left: 0;
            border-color: var(--black);
        }
    }

    &-MinicartItemCount {
        color: var(--white) !important;
    }

    &-TempMessage {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100vw;
        background: #d7282e;
        color: #fff;
        font-size: 1.6rem;
        text-align: center;
        z-index: 999999;

        @include before-desktop {
            bottom: unset;
            top: 0;
            height: 100px;
            overflow-x: hidden;
            overflow-y: auto;
            position: relative;
        }

        > .CmsBlock-Wrapper {
            padding: 1.2rem 3.6rem;
        }
    }
}

.StoreSwitcherBanner {
    &-Container {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 9999;
        background: #212121;
        width: 100%;
        padding: 1.2rem 2.4rem;

        @include before-desktop {
            bottom: unset;
            top: 0;
            position: relative;
            padding: 2.4rem;
        }
    }

    &-Content {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 0 4.8rem;

        @include mobile {
            flex-direction: column;
            align-items: flex-start;
            text-align: left;
            padding-left: 0;
        }
    }

    &-Text {
        font-size: 1.4rem;
        color: var(--white);
        margin-right: 2.4rem;

        @include mobile {
            margin-right: 0;
        }
    }

    &-SwitcherButton.Button {
        padding: .8rem 2.4rem;
        font-size: 1.4rem;
    }

    &-Actions {
        @include mobile {
            margin-top: 2.4rem;
        }
    }

    &-CloseButton {
        font-size: 0;
        display: inline-block;
        height: 36px;
        width: 36px;
        background-image: url(https://www.larryvsharry.com/pub/media/hoeks-pwa/close.svg);
        background-size: 20px;
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;

        @include mobile {
            transform: none;
            top: -.6rem;
            right: -.6rem;
        }
    }
}

.StoreSwitcherBanner {
    &-Container {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 9999;
        background: #212121;
        width: 100%;
        padding: 1.2rem 2.4rem;

        @include before-desktop {
            bottom: unset;
            top: 0;
            position: relative;
            padding: 2.4rem;
        }
    }

    &-Content {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 0 4.8rem;

        @include mobile {
            flex-direction: column;
            align-items: flex-start;
            text-align: left;
            padding-left: 0;
        }
    }

    &-Text {
        font-size: 1.4rem;
        color: var(--white);
        margin-right: 2.4rem;

        @include mobile {
            margin-right: 0;
        }
    }

    &-SwitcherButton.Button {
        padding: .8rem 2.4rem;
        font-size: 1.4rem;
    }

    &-Actions {
        @include mobile {
            margin-top: 2.4rem;
        }
    }

    &-CloseButton {
        font-size: 0;
        display: inline-block;
        height: 36px;
        width: 36px;
        background-image: url(https://www.larryvsharry.com/pub/media/hoeks-pwa/close.svg);
        background-size: 20px;
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;

        @include mobile {
            transform: none;
            top: -.6rem;
            right: -.6rem;
        }
    }
}
