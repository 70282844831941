/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';
@import '../../../../../../src/style/abstract/theme-abstract';

.cms-module-hero {
    &.form-hero {
        min-height: var(--height-100vh);
        height: auto;

        .vertical-center {
            display: flex;
            align-items: center;
        }
    }
}

.BikeForm {
    &-Outer {
        background: #fff;
        margin: 4.8rem 0!important;
        border-radius: 30px;
        box-shadow: -8px 8px 10px 0 rgba(0,0,0,0.2);
        padding: 4.8rem;
    }

    &-Title {
        color: var(--primary-base-color);
        margin-bottom: 0;
    }

    .ohnohoney {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        height: 0;
        width: 0;
        z-index: -1;
    }

    &-Fields {
        input {
            width: 100%;
        }
    }

    &-Button {
        width: 100%;
    }

    &-Newsletter,
    &-TermsAndConditions,
    &-IANR {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        margin-top: .6rem;

        .Field {
            margin: 0;
            margin-right: .6rem;
        }
    }

    &-Newsletter {
        margin-top: 2.4rem;
    }

    &-TACLink {
        padding-left: 5px;
    }

    .Field-Label_isRequired::before {
        content: "* ";
        color: red;
    }
}
