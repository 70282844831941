/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/theme-abstract';

.MyAccountNews {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    &-NewsImage {
        width: 60%;
        // display: block;
    }

    &-Text {
        padding-top: 20px;
    }

    &-Table {
        table-layout: fixed !important;
    }

    &-Item {
        // Removes top padding for whole first row on desktop
        &:first-child {
            & td {
                @include desktop {
                    padding-top: 0 !important;
                }
            }
        }

        &:last-child {
            & td {
                @include desktop {
                    padding-bottom: 0 !important;
                }
            }
        }
    }

    &-ItemThumbnail {
        width: 1px;
        padding-left: 0;
    }

    &-ItemImage {
        display: block;
        width: 100px;
        height: 45px;
        object-fit: cover; 
    }
    
    &-ItemTitle {
        font-weight: bold;

        @include desktop {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
    
    &-ItemSubject {
        text-align: left;
        width: 1px;
        white-space: nowrap;
        color: var(--primary-base-color);
    }
    
    &-ItemDate {
        white-space: nowrap;
        
        @include desktop {
            width: 80px !important;
        }
    }

    &-Loader {
        @include desktop {
            margin-top: 25%; // I dont know why it has to be 25% and not 50%. But might have something to do with
            transform: translateY(-50%);
        }
    }
}

.hidden {
    display: none;
}