.cms-section {
    &.cms-slider {
        .slick-slider {

            @include before-desktop {
                margin: 0 3.6rem;
            }

            .slick-arrow {
                background: var(--button-background);
                height: 40px;
                width: 40px;
                border-radius: 50%;
                border-top-left-radius: 50%;
                border-top-right-radius: 50%;
                border-bottom-right-radius: 50%;
                border-bottom-left-radius: 50%;
                z-index: 1;
                transition: all ease .3s;

                &::before {
                    font-family: inherit;
                    font-weight: bold;
                    content: '';
                    background-image: url(https://www.larryvsharry.com/pub/media/hoeks-pwa/arrow-left.svg);
                    background-size: 18px;
                    background-position: center;
                    background-repeat: no-repeat;
                    height: 100%;
                    width: 100%;
                    top: 0;
                    left: 0;
                    display: block;
                    opacity: 1;
                }

                &.slick-next {
                    right: -22px;

                    @include before-desktop {
                        right: -32px;
                    }

                    &::before {
                        transform: rotateZ(180deg);
                    }
                }

                &.slick-prev {
                    left: -22px;

                    @include before-desktop {
                        left: -32px;
                    }
                }

                &:hover {
                    background: var(--button-hover-background);
                }
            }

            .slick-list {
                margin: 0 -2.4rem;

                .slick-track {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    padding: 2.4rem 0;
                    min-width: 100%;

                    .slick-slide {
                        padding: 0 2.4rem;
                        height: unset;

                        > div {
                            height: 100%;
                        }

                        .SliderWidget-Figure {
                            display: flex !important;
                            height: 100%;
                            background: var(--white-background);
                            box-shadow: var(--cms-box-shadow);
                            border-radius: var(--theme-border-radius);
                            overflow: hidden;
                            flex-direction: column;

                            .SliderWidget-Figcaption {
                                position: relative;
                                top: unset;
                                transform: unset;
                                height: auto;
                                padding: 3.6rem 3.6rem 8rem 3.6rem;
                                flex: auto;

                                .slider-popup-bottom {
                                    position: absolute;
                                    bottom: 3.6rem;
                                }

                                *:last-child {
                                    margin-bottom: 0;
                                }
                            }

                            .Image-Image {
                                border-radius: 0;
                            }
                        }
                    }
                }
            }

            .slick-dots {
                display: none !important;
            }
        }
    }

    &.cms-feature-slider {
        .slick-slider {
            .slick-list {
                .slick-track {
                    .slick-slide {
                        .SliderWidget-Figure {
                            box-shadow: none;

                            .SliderWidget-Figcaption {
                                padding: 0;

                                .cms-section {
                                    .cms-section-container {
                                        width: 100%;
                                        max-width: 100%;
                                    }
                                }
                            }

                            .Image-Image {
                                border-radius: var(--theme-border-radius);
                            }
                        }
                    }
                }
            }
        }
    }
}
