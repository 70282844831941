/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/theme-abstract';

.MyAccountProductBackorders {
    &-Date {
        font-weight: 600;
        cursor: pointer;
    }

    &-Text {
        font-weight: 500;
        cursor: pointer;
    }
    & .Table {
        table-layout: fixed;

        &-HeaderData {
            &_type {
                &_nextDelivery {
                    @include desktop {
                        width: 100px !important;
                    }
                }

                &_backOrders {
                    @include desktop {
                        width: 100px !important;
                    }
                }

                &_openOrders {
                    @include desktop {
                        width: 80px !important;
                    }
                }
            } 
        }

        &-ItemData {
            &_type {
                &_products {
                    @include desktop {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
            }

            @include before-desktop {
                &_mobile {
                    &_isHidden {
                        display: none;
                    }
                    
                    &_isLast {
                        border-bottom-left-radius: var(--theme-border-radius);
                        border-bottom-right-radius: var(--theme-border-radius);
                        padding-bottom: var(--b2b-spacing-size) !important;
                    }
                }
            }
        }
    }
}