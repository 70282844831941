/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/theme-abstract';

.MyAccountProfile {
    $block: &;

    display: grid;
    grid-auto-columns: 1fr 1fr;
    grid-auto-rows: auto;
    column-gap: 2.4rem;
    row-gap: 2.4rem;
    position: relative;

    @include before-desktop {
        grid-auto-columns: auto 0;
        column-gap: 0;
    }

    &-LogoutButton {
        position: absolute;
        right: 0;
        top: -55px;

        @include mobile {
            position: static;
        }
    }

    &-Button {
        text-align: center;
        
        @include mobile {
            width: 100%;
        }
    }

    &-GridFill {
        width: 100%;
        height: 100%;

        @include before-desktop {
            display: none;
        }
    }

    &-CustomerDataTitle {
        font-size: var(--h4-font-size);
        color: var(--primary-base-color);
        font-weight: var(--header-font-weight);
        margin-right: 1.2rem;
        grid-column: span 2;

        @include mobile {
            margin-top: 1.2rem;
        }
    }

    &-AddressTitle {
        font-size: var(--h4-font-size);
        color: var(--primary-light-color);
        font-weight: var(--header-font-weight);
        margin-right: 1.2rem;
        grid-column: span 2;

        @include mobile {
            margin-top: 1.2rem;
        }
    }

    &-Agreements {
        display: flex;
        grid-column: span 2;
    }

    &-AgreementWrapper {
        margin-top: 3.6rem;
        margin-right: 6rem;

        &:last-child {
            margin-right: 0;
        }
    }
    
    &-AgreementLink {
        display: block;
        text-decoration: underline;
        line-height: 2.4rem;
    }
}