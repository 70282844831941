
.cms-row {
    &.cms-swipe-row {
        @include before-desktop {
            overflow-x: scroll;
            flex-wrap: nowrap;
            margin: 0 auto;
            width: auto;
            justify-content: flex-start;
        }

        .cms-col {
            @include before-desktop {
                width: 45%;
                max-width: 45%;
                min-width: 45%;
            }

            @include mobile {
                width: 85%;
                max-width: 85%;
                min-width: 85%;
            }
        }
    }
}
