/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/theme-abstract';

:root {
    --checkout-address-table-border: #fff;
}

.CheckoutAddressTable {
    &-Button {
        border: 0;
        outline: 0px solid var(--checkout-address-table-border);

        &::before {
            top: 6px;
            right: 6px;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background: var(--checkout-address-table-border);

            @include before-desktop {
                top: 1.6rem;
                right: 1.6rem;
            }
        }

        &::after {
            @include before-desktop {
                top: 2.2rem;
                right: 2.1rem;
            }
        }
        
        &:not(&_isSelected) {
            &:hover {
                --check-mark-opacity: 1;
                --checkout-address-table-border: #fff;
                outline: 1px solid var(--checkout-address-selected-color);
            }
        }

        &_isSelected {
            --check-mark-opacity: 1;
            --checkout-address-table-border: var(--checkout-address-selected-color);
            --check-mark-color: var(--checkout-address-table-check-mark-selected-color);
            outline: 1px solid var(--checkout-address-selected-color);

            & .Table {
                & .Table-Item {
                    &:first-child {
                        & .Table-ItemData {
                            @include before-desktop {
                                padding-top: 4.8rem !important;
                            }
                        }
                    }
                }
            }
        }
    }
}