.cms-manuals {
    &-box {
        height: 100%;
        min-height: 250px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 0!important;
        box-shadow: 0 0 0 0 transparent;
        transition: all ease .3s;
        top: 0;

        &:hover {
            box-shadow: 0 3px 4px 1px rgba(0,0,0,0.1);
            top: -4px;
        } 
    }

    &-content {
        h4 {
            margin-bottom: 0;
            font-size: 1.6rem;
        }
    }

    &-link {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 10;
        opacity: 0;
        font-size: 0;
    }
}