/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/theme-abstract';

:root {
    --menu-desktop-height: var(--header-nav-height);

    --menu-second-level-color: var(--b2b-title-color);
    --menu-item-hover-color: var(--b2b-title-color);
    --menu-desktop-background-color: rgba(210, 210, 210, 0.7);

    @include before-desktop {
        --menu-item-height: 60px;
        --menu-select-height: 40px;
    }

    @include mobile {
        --menu-item-height: 40px;
        --menu-select-height: 30px;
    }
}

// This is not supposed to be here, but it is too extensive to overwrite the page for this one chage
.MenuPage {
    @include before-desktop {
        background: var(--white);
    }
}

.Menu {
    $block: &;
    &-MenuWrapper {
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;

        @include before-desktop {
            padding: 0rem;

            .StoreSwitcher,
            .CurrencySwitcher {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-end;
            }

            .Field {
                &_type {
                    &_select {
                        margin-top: 0;
                        margin-bottom: 0;
                    }
                }
            }

            .FieldSelect {
                &-Select {
                    padding: 0 2.4rem;
                    height: var(--menu-select-height);
                    background: var(--primary-base-color);
                    font-weight: 500;
                    font-size: 1.6rem;
                    border: none;
                    color: var(--white);
                    direction: ltr;

                    option {
                        direction: ltr;
                    }
                }

                &::after {
                    right: 2.4rem;
                    border-color: var(--white);
                }
            }
        }

        @include mobile {
            .FieldSelect {
                &-Select {
                    padding: 0;
                    padding-left: 1.2rem;
                    padding-right: 1.2rem;
                    font-size: 1.2rem;
                    font-weight: 400;
                }

                &::after {
                    right: 1.2rem;
                }
            }
        }

        @media screen and (min-width: 1025px) and (max-width: 1050px) {
            padding-left: calc(var(--header-logo-width) + 1.2rem + 1.2rem);
        }

        @include desktop {
            padding-left: calc(var(--header-logo-width) + 2.4rem + 2.4rem);
        }

        // NOTE: (Bjarne) Info to others: Use 'Menu-ItemFigure_type_hideOnDesktop' in ScandiWeb menu manager,
        //                                for 'Menu Item CSS Class' field to hide menu items instead of this hack.
        // li {
        //     &:nth-last-child(-n+1) {
        //         @include desktop {
        //             display: none;
        //         }
        //     }
        // }
    }

    &-SelectFields {
        @include before-desktop {
            order: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: 2.4rem 9.6rem 0 9.6rem;

            > div {
                width: 50%;
                border: 1px solid var(--white); 

                .Field {
                    width: 100%;
                }

                &:first-child {
                    margin-right: 1.2rem;
                }
            }

            > .StoreSwitcher {
                order: 1;
            }

            > .CurrencySwitcher {
                order: 2;
            }
        }

        @include mobile {
            padding: 1.2rem 4.8rem 0 4.8rem;
        }
    }

    &-MainCategories {
        max-width: 100%;
        padding: {
            left: 0;
            right: 0;
        };
        
        @include before-desktop {
            min-height: calc(100vh - var(--header-total-height) - var(--navigation-tabs-height));
            background: var(--primary-base-color);
            justify-content: center;
        }
    }

    &-SubMenu {
        @include before-desktop {
            min-height: calc(100vh - var(--header-total-height) - var(--navigation-tabs-height));
            display: flex;
            flex-direction: column;
            width: 100%;
            justify-content: center;
            padding-bottom: var(--navigation-tabs-height);
        }
    }

    &-Item {
        @media screen and (max-width: 1300px) {
            padding: 0 2.4rem 0 0;
        }

        @include before-desktop {
            padding: 0;
            margin-top: 0;
            margin-bottom: 0;
            width: 100%;
        }

        &List {
            @include before-desktop {
                order: 2;
                flex: 1;
                display: flex;
                align-items: flex-start;
                justify-content: center;
                flex-direction: column;
                padding: 0;
                padding-bottom: calc(var(--menu-select-height) * 2);
                height: calc(var(--menu-select-height) * 2);
            }
        }

        &Figure {
            @include before-desktop {
                --menu-item-height: 40px;
                height: calc(var(--menu-item-height) * 2);
                padding: 0 9.6rem;
            }

            @include mobile {
                --menu-item-height: 30px;
                padding: 0 4.8rem;
            }

            &_type {
                &_main{
                    text-transform: none;

                    @include before-desktop {
                        background-color: var(--primary-base-color);
                        border: none;
                        color: var(--white);
                    }
                }
            }
        }

        &Caption {
            font-weight: var(--header-font-weight);

            @include desktop {
                font-size: 1.6rem;
            }

            @include before-desktop {
                font-size: 2.6rem;
                font-weight: normal;
            }

            @include mobile {
                font-size: 2rem;
            }

            &_type {
                &_main {
                    @include desktop {
                        color: var(--body-heading-color);
                    }
                }
            }
        }
    }

    &-CompareLinkWrapper {
        display: none;
    }

    &-Promotion {
        display: none;
    }

    &-SubCategories {
        padding: 0;
        padding-left: calc(24px + 60px + 24px);
        max-width: none;

        &Wrapper {
            left: 0;
        }
    }

    &-SubItemWrapper {
        display: flex;
        flex-basis: auto;
        
        @include desktop {
            margin-bottom: 0;
            height: 8rem;
        }

        &_isHideOnDesktop {
            @include desktop {
                display: none;
            }
        }

        & #{$block}-Link {
            text-transform: none;
            display: flex;

            @include before-desktop {
                padding: 0;
            }
        }
    }

    &Overlay {
        background-color: transparent;
    }
    
    @keyframes expand {
        from {
            height: 0;
        }
    }
    
    &-SubCategoriesWrapper {
        top: calc(var(--header-nav-height) + 0px);
        border-top: 1px solid rgba(0,0,0,.05);
        animation: expand .1s ease-in-out;
        height: 80px;
        overflow: hidden;
    }
}
