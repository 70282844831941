.Form {
    $block: &;

    &-Row {
        display: flex;
        align-items: flex-end;

        @include before-desktop {
            flex-direction: column;
        }
    }

    &-Label {
        display: block;
        font-size: var(--font-size);
        margin-bottom: var(--b2b-input-label-margin-bottom);
    }

    &-Input {
        width: 100%;
    }

    &-InputGroup {
        margin-right: 2%;
        margin-top: 1.2rem;
        
        width: 32%;

        @include before-desktop {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-right: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        &_align {
            &_right {
                align-items: flex-end;
            }

            &_rightBeforeDesktop {
                @include before-desktop {
                    align-items: flex-end;
                }
            }
        }
    }

    &-CheckBox {
        display: block;
        position: relative;
        padding-left: 3rem;
        margin-bottom: 1.2rem;
        cursor: pointer;
        font-size: 1.4rem;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        line-height: 1.8rem;

        & input {
            position: absolute;
            opacity: 0;
            visibility: hidden;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        &:hover input ~ #{$block}-CheckMark {
            border-color: var(--primary-base-color);
            
            &:after {
                visibility: visible;
                opacity: 1;
                border-color: var(--primary-base-color);
            }
        }

        & input:checked ~ #{$block}-CheckMark {
            background-color: var(--primary-base-color);
            border-color: var(--primary-base-color);
        }

        &:hover input:checked ~ #{$block}-CheckMark {
            // background-color: var(--primary-light-color);
            // border-color: var(--primary-light-color);

            &:after {
                border-color: var(--white);
            }
        }

        & input:checked ~ #{$block}-CheckMark:after {
            visibility: visible;
            opacity: 1;
        }
    }

    &-CheckMark {
        position: absolute;
        top: 0;
        left: 0;
        height: 1.3em;
        width: 1.3em;
        background-color: var(--white);
        border: 0.1rem solid var(--input-border-color);
        transition-property: background-color border-color;
        transition-duration: 500ms;

        &:after {
            content: "";
            position: absolute;
            visibility: hidden;
            opacity: 0;
            transition-property: opacity visibility;
            transition-duration: 500ms;
            left: 50%;
            top: 45%;
            width: 0.2em;
            height: 0.5em;
            border: solid var(--white);
            border-width: 0 0.2em 0.2em 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: translate(-50%, -55%) rotate(45deg);
        }
    }
}