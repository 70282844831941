.cms-section-container {
    &.full-banner,
    &.full.full-banner {
        width: 100%;
        max-width: 100%;

        .cms-row {
            &.center-cols {
                > .cms-col {
                    @include before-desktop {
                        padding: {
                            top: var(--cms-vertical-padding-x2);
                            bottom: var(--cms-vertical-padding-x2);
                        };
                    }

                    // > .cms-col-inner {
                    //     padding: var(--cms-vertical-padding-x3);

                    //     @include mobile {
                    //         padding: var(--cms-vertical-padding-x2);
                    //     }
                    // }
                }
            }
        }

        > .cms-row {
            .cms-col {
                .cms-col-inner {
                    border-radius: var(--theme-border-radius);
                }
            }
        }
    }
}

.cms-col {
    &.cms-banner {
        margin-bottom: 0 !important;

        > .cms-col-inner {
            padding-top: 300px;
            background-size: cover;
            background-position: center;
            border-radius: var(--theme-border-radius);
        }
    }
}
