/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/theme-abstract';

.StoreSwitcher {
    @media screen and (min-width: 1025px) and (max-width: 1150px) {
        margin-right: 0!important;
    }

    @include desktop {
        display: block;

        margin-right: 1.2rem;
        position: relative;

        &-Title {
            padding-right: 1.2rem;

            &::after {
                right: 0;
            }
        }
    }

    @include before-desktop {
        margin-bottom: 0;
        border: none;
    }
}
