/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/theme-abstract';

.NewsletterSubscription {
    input {
        &[type="text"] {
            border-top-left-radius: var(--theme-border-radius-button);
            border-bottom-left-radius: var(--theme-border-radius-button);
        }
    }

    .FieldForm-Button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}