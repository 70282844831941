.cms-module-hero {
    height: var(--height-100vh);
    min-height: 700px;

    @include before-desktop {
        height: auto;
        min-height: 0;
    }

    &-videoe-background {
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
        pointer-events: none;
    
        &::-webkit-media-controls {
            display: none;
          }
    }

    .cms-button {
        &-secondary {
            background-color: var(--white);
            border-color: var(--white);

            &:hover {
                background-color: var(--cms-button-secondary-hover-background-color);
                border-color: var(--cms-button-secondary-hover-background-color);
            }
        }
    }
}
