/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/theme-abstract';

:root {
    --footer-background-color: var(--secondary-dark-color);
    --footer-heading-color: var(--body-heading-color-light);
    --footer-content-color: var(--body-content-color-light);
}

.Footer {
    color: var(--footer-content-color);

    a {
        color: var(--footer-content-color);
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: var(--body-heading-color-light);
    }

    &-Content {
        background-color: var(--footer-background-color);
        background-position: right center;
        background-size: auto 100%;
        background-repeat: no-repeat;
        min-height: 0;

        @include desktop {
            background-image: url(https://www.larryvsharry.com/pub/media/hoeks-pwa/lvsh-grafik.png);
        }

        // @include before-desktop {
        //     background-size: 80% auto;
        //     background-position: right bottom;
        // }
    }

    &-Columns {
        padding: 6rem 1.2rem;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        @include after-mobile {
            padding: 10rem 1.2rem;
        }

        @include before-desktop {
            padding: 6rem 1.2rem 16rem 1.2rem;
        }

        @include tablet {
            padding: 6rem 4.8rem 16rem 4.8rem;
        }

        .CmsBlock-Wrapper {
            width: 100%;
        }
    }

    &-ColumnContainer {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;

        @include before-desktop {
            flex-direction: column;
        }

        @include tablet {
            flex-direction: row;
            justify-content: stretch;
            flex-wrap: wrap;
        }
    }

    &-Column {
        width: 20%;
        max-width: 100%;

        @include before-desktop {
            width: 100%;
            text-align: center;
        }

        @include tablet {
            width: 50%;
            max-width: 50%;
            margin-bottom: 50px;
            text-align: left;
        }

        &:not(:last-of-type) {
            @include tablet {
                max-width: 50%;
                width: 50%;
            }
        }

        &:first-of-type {
            @include before-desktop {
            width: 100%;
            max-width: 100%;
            text-align: center;
            align-items: center;
            }

            .Image {
                @include before-desktop {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        
        }

        &_isFull {
            width: 100%;
            flex: 1;
        }

        &_isNewsletter {
            padding-left: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding-top: 4.8rem;

            .NewsletterSubscription {
                .FieldForm-Fieldset {
                    .Field {
                        input {
                            @include desktop {
                                width: 400px;
                            }
                        }
                    }
                }
            }
        }

        p {
            margin-bottom: .6rem;
        }
    }

    &-Title {
        font-size: 1.8rem;
        margin-bottom: 1.2rem;
    }
}
