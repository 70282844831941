/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';
@import '../../../../../../src/style/abstract/theme-abstract';

.MyAccountProductBacklogPopup {
    &-FlexibleMinWidth {
        width: 800px;
    }

    &-ProductInfo {
        display: flex;

        @include mobile {
            flex-direction: column;
        }

        &Image {
            height: 12rem !important;
            width: 16rem !important;
            border-radius: var(--theme-border-radius);
            background-color: var(--white) !important;

            & img {
                object-fit: cover;
            }
        }

        &Title {
            display: block;
            font-size: var(--h4-font-size);
            font-weight: var(--header-font-weight);
            color: var(--primary-light-color);
            flex-grow: 1;
            margin-top: 0 !important;
            margin-left: 2.4rem;
            padding-right: 3.6rem;

            @include mobile {
                margin-left: 0;
                padding-right: 4.8rem;
                order: -1;
            }
        }
    }

    &-Headline {
        &Title {
            font-size: var(--h5-font-size);
            font-weight: var(--header-font-weight);
            color: var(--primary-base-color);
            margin-top: 3.6rem;
            margin-bottom: 0;
        }
    }

    &-Table {
        & tbody {
            & .Table-Item {
                box-shadow: none !important;
            }
        }
    }

    &-Actions {
        display: flex;
        
        &Item {
            margin-top: 1.2rem;
            margin-right: 1.2rem;
            font-size: var(--font-size);

            &:last-child {
                margin-right: 0;
            }
        }

        &Link {
            text-decoration: underline;
        }
    }
}
