/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';
@import '../../../../../../src/style/abstract/theme-abstract';

.MyAccountProductBacklog {
    &-SearchFilterHeadline {
        font-size: var(--font-size);
        font-weight: bold;
    }

    & .Field {
        margin-top: 0;
    }

    &-Content {
        padding-top: 2.4rem;
    }

    &-ItemDataThumbnail {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        height: 100% !important;
        
        @include before-desktop {
            //width: 8rem;
            left: 50%;
            transform: translate(-50%);
        }

        border-top-left-radius: var(--theme-border-radius);
        
        @include before-desktop {
            border-top-right-radius: var(--theme-border-radius);
        }

        @include desktop {
            border-bottom-left-radius: var(--theme-border-radius);
        }

        & img {
            position: absolute !important;

            @include desktop {
                object-fit: cover !important;
            }
        }
    }

    &-HeaderDataSku {
        padding-left: var(--b2b-spacing-size);
    }
    
    &-ItemDataSku {
        padding-left: var(--b2b-spacing-size);
    }
}