.Input-Validation_type_success {
    // Checkmark for form validation
    background-image: url('icons/check.svg');
    background-repeat: no-repeat;
    background-position-x: 97%;
    background-position-y: center;
}

.Input-Validation_type_failure {
    // Checkmark for form validation
    background-image: url('icons/cross.svg');
    background-repeat: no-repeat;
    background-position-x: 96%;
    background-position-y: center;
    background-size: 1rem;
}