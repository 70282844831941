/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/theme-abstract';

:root {
    @include before-desktop {
        --searchfield-height: 50px;
    }

    @include mobile {
        --searchfield-height: 30px;
    }
}

.SearchField {
    margin: 0;
    padding: 0;
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include desktop {
        margin-right: 1.2rem;
    }

    @media screen and (min-width: 1025px) and (max-width: 1150px) {
        margin-right: 0;
    }

    @include before-desktop {
        margin: 0 9.6rem;

        @at-root .Header_name_search & {
            margin-left: calc(4.8rem + 1.2rem);
        }
    }

    @include mobile {
        margin: 0 4.8rem;

        @at-root .Header_name_search & {
            margin-left: 1.2rem;
        }
    }

    &-Wrapper {
        margin: 0;
        width: 100%;
    }

    &-SearchIcon {
        width: 35px;
        height: 35px;

        &::before {
            left: 45%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &-CloseIcon {
        width: 35px;
        height: 35px;

        &::before,
        &::after {
            left: 50%;
            top: 52%;
            transform: translate(-50%, -50%) rotate(-45deg);
        }

        &::after {
            transform: translate(-50%, -50%) rotate(45deg);
        }
    }

    &-SearchWrapper {
        top: calc(var(--header-total-height) + 1px);
    }

    &-SearchInnerWrapper {

        // Nested by Scandi per default
        .SearchField-SearchIcon {
            top: 44px;
            left: 34px;
        }
    }

    &-Input {
        @include before-desktop {
            height: var(--searchfield-height);
        }
    }

    &-Placeholder {
        @include before-desktop {
            height: var(--searchfield-height);
            line-height: var(--searchfield-height);
            top: 0;
        }
    }
}
